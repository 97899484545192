/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import differenceInCalendarDays from 'date-fns/difference_in_calendar_days'
import { Icon, Dropdown, Menu } from 'antd'

import { NAut } from '~/modules/NAuth'
import { notOnPlan } from '~/modules/NAuth/Alerts'
import Api from '~/modules/Api'
import CustomerService from '~/modules/CustomerService'
import TopMenu from './TopMenu'

import logoPICSIZE from 'img/logo-picsize.png'

class TopHeader extends Component {
  $customerService

  constructor(props, context) {
    super(props, context)

    this.state = {
      get hasOnlySel() {
        const { subscription } = NAut.userData
        if (subscription && subscription.limits) {
          return subscription.limits.sel && !subscription.limits.site
        }
        return false
      },
      galPictures: 0,
      colPictures: 0
    }
  }

  componentDidMount() {
    this.$customerService = CustomerService.stats.subscribe((data) => {
      this.setState({
        galPictures: data.galleryInfo.count,
        colPictures: data.collectionInfo.count
      })
    })
  }

  componentWillUnmount() {
    this.$customerService.unsubscribe()
  }

  // eslint-disable-next-line react/sort-comp
  static async crossLoginUrl(service) {
    const result = await Api.post('/login/cross', { service })
    if (!result.onPlan) {
      await notOnPlan()
      return
    }
    return result.url
  }

  render() {
    function link(str) {
      return async (e) => {
        if (e.button === 1) {
          const url = await TopHeader.crossLoginUrl(str)
          if (url) window.open(url)
        } else {
          TopHeader.crossLogin(str)
        }
      }
    }

    const iconStyle = {
      width: '26px',
      display: 'inline-block',
      textAlign: 'center'
    }

    const menu = (
      <Menu>
        <Menu.Item key="0" className="ant-dropdown-menu-item-active">
          <a onMouseUp={link('select')}>
            <i style={iconStyle} className="fo fo-pictures" />
            {' '}
            <span>Galerias</span>
          </a>
        </Menu.Item>
        <Menu.Item key="2">
          <a onMouseUp={link('site')}>
            <i style={iconStyle} className="fo fo-site" />
            {' '}
            <span>Site</span>
          </a>
        </Menu.Item>
      </Menu>
    )
    return (
      <div className="page-header-inner">
        <a
          className="menu-toggler responsive-toggler"
          data-toggle="collapse"
          data-target=".navbar-collapse"
        >
          <Icon type="bars" />
        </a>
        <div style={{ display: 'inline-block', float: 'left' }}>
          <img
            src={logoPICSIZE}
            alt="picsize-logo"
            style={{ padding: '19px 12px', width: '170px' }}
          />
        </div>
        <div className="page-actions">
          {!this.state.hasOnlySel && (
            <Dropdown overlay={menu} trigger={['click']} overlayStyle={{ zIndex: 9999 }}>
              <button type="button" className="btn btn-circle btn-outline red dropdown-toggle">
                <i className="fa fa-check-square-o" />
                &nbsp;
                <span className="hidden-sm hidden-xs">Soluções&nbsp;</span>
                &nbsp;
                <i className="fa fa-angle-down" />
              </button>
            </Dropdown>
          )}
        </div>
        <div className="page-top">
          <FreeNotice galPictures={this.state.galPictures} colPictures={this.state.colPictures} />
          <TrialNotice />
          <TopMenu />
        </div>
      </div>
    )
  }
}

function FreeNotice(props) {
  const { subscription } = NAut.userData
  const style = {
    display: 'inline-block',
    marginTop: '17.5px',
    marginLeft: '10px',
    padding: '5px 10px'
  }

  if (
    !subscription
    || !Array.isArray(subscription.plan_codes)
    || !subscription.plan_codes.filter((i) => i === 'col_freemium').length
  ) {
    return null
  }

  return (
    <div className="alert alert-danger" style={style}>
      Você já utilizou
      {' '}
      <strong>{props.galPictures}</strong>
      {' '}
de
      {' '}
      <strong>{subscription.limits.col_max_collections}</strong>
      {' '}
Galerias de Entrega e
      {' '}
      <strong>{props.colPictures}</strong>
      {' '}
de
      {' '}
      <strong>{subscription.limits.sel_max_collections}</strong>
      {' '}
Coleções para Seleção.
      <a
        target="_blank"
        href="https://www.picsize.com.br/upgrade"
        className="btn red-mint"
        style={{ verticalAlign: 'baseline', padding: '1px 8px', marginLeft: '6px' }}
      >
        Aumentar Limite
      </a>
    </div>
  )
}

FreeNotice.propTypes = {
  colPictures: PropTypes.any,
  galPictures: PropTypes.any
}

function TrialNotice() {
  const { subscription } = NAut.userData
  const style = {
    display: 'inline-block',
    marginTop: '17.5px',
    marginLeft: '10px',
    padding: '5px 10px'
  }

  if (!subscription || !NAut.isTrial) return null

  if (NAut.isTrialExpired) {
    return (
      <div className="alert alert-danger  " style={style}>
        <strong>Atenção!</strong>
        {' O seu período de testes expirou! '}
        <a href="https://www.picsize.com.br/contato">Clique aqui</a>
        {' para entrar em contato'}
      </div>
    )
  }

  return (
    <div className="alert alert-warning" style={style}>
      <strong>Atenção!</strong>
      {' O seu período de testes termina em '}
      <strong>
        {differenceInCalendarDays(subscription.expiration_date, new Date())}
        {' dias'}
      </strong>
      <a
        target="_blank"
        href="https://www.picsize.com.br/upgrade"
        className="btn yellow-mint"
        style={{ verticalAlign: 'baseline', padding: '1px 8px', marginLeft: '6px' }}
      >
        Ver Planos
      </a>
    </div>
  )
}

TopHeader.propTypes = {
  children: PropTypes.element
}

export default TopHeader
