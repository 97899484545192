import React from 'react'
import {Link} from 'react-router'

function RecoverForm(props) {
  return (
    <form onSubmit={props.onSubmit}>
      <Alert error={props.errorStatus}/>
      <div className="form-group password password">
        <label
          className="control-label password"
          htmlFor="password">Digite sua nova senha</label>
        <input
          className="form-control password" type="password" onChange={props.handleChange} name="password"/>
      </div>
      {
        props.inputs.password.length > 5 ?
        <div className="form-group password password_check">
          <label
            className="control-label password"
            htmlFor="password2">Confirmação de Senha</label>
          <input
            className="form-control password" type="password" onChange={props.handleChange} name="password_check"/>
        </div> : null
      }
      <div className="form-actions">
        <div className="row">
          <div className="col-md-6">
            <Link to="/login">
              Voltar
            </Link>
          </div>
          <div className="col-md-6">
            <input
              type="submit"
              name="commit"
              defaultValue="Alterar senha"
              className="btn signup pull-right uppercase" />
          </div>
          </div>
        </div>
      </form>
  )
}

function Alert(props) {
  if (!props.error) return null
  return (
    <div className="alert alert-danger animated fadeIn" >
      {props.error}
    </div>
  )
}

export default RecoverForm
