import Main from '~/layouts/Main'
import Api from '~/modules/Api'
import { NAut } from '~/modules/NAuth'

import LoginView from '~/pages/Login'
import RecoverView from '~/pages/Recover'
import ErrorView from '~/pages/Error'
import Signup from '~/pages/Signup'
import Account from '~/pages/Account'
import PlatformList from '~/pages/PlatformList'
import AccountInfo from '~/pages/Account/Info'
import AccountProfile from '~/pages/Account/Profile'
import Financial from '~/pages/Account/Financial'

import Support from '~/pages/Support'

const routes = {
  childRoutes: [
    {
      path: '/',
      component: Main,
      indexRoute: { onEnter: (nextState, replace) => redirToService('select') },
      onEnter: (nextState, replace) => {
        if (!NAut.isAuthenticated) {
          return replace('/login')
        }
      },
      childRoutes: [
        { path: 'account/profile', component: AccountProfile },
        {
          path: 'account',
          component: Account,
          childRoutes: [
            { path: 'info', component: AccountInfo },
            { path: 'profile', component: AccountProfile },
            { path: 'financial', component: Financial }
          ]
        },
        { path: 'platforms', component: PlatformList },
        { path: 'support', component: Support },
        {
          path: '/logout',
          onEnter: (nextState, replace) => {
            NAut.logout()
          }
        }
      ]
    },
    {
      path: '/signup',
      component: Signup
    },
    {
      path: '/login',
      component: LoginView
    },
    {
      path: '/recover',
      component: RecoverView
    },
    {
      path: '/error',
      component: ErrorView
    },
    {
      path: '/error/:reason',
      component: ErrorView
    }
  ]
}

export default routes

// TODO: Rewrite
async function redirToService(service) {
  const result = await Api.post('/cross/url', { service })
  if (!result || !result.url) {
    console.warn(result)
    return
  }
  window.location = result.url
}
