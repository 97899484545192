import { Component } from 'react'
import { Router, browserHistory } from 'react-router'

import { NAut } from '~/modules/NAuth'
import CustomerService from '~/modules/CustomerService'
import routes from './routes'

export default class AppComponent extends Component {
  constructor(props, context) {
    super(props, context)

    this.state = { ready: false }
  }

  async componentDidMount() {
    await NAut.init()
    this.setState({ ready: true })

    if (NAut.isAuthenticated) {
      // TODO: Rewrite
      if (NAut.isTrialExpired) {
        return NAut.logout()
      }

      CustomerService.refreshStats()
    }
  }

  render() {
    return <div>{this.state.ready && <Router history={browserHistory}>{routes}</Router>}</div>
  }
}
