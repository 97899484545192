import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { NAut } from '~/modules/NAuth'
import Api from '~/modules/Api'
import SignupForm from '../widgets/SignupForm'
import fullLogo from 'img/picsize.png'

import 'custom/login.scss'

class Signup extends Component {
  constructor(props, context) {
    const storedMessage = localStorage.getItem('successMessage')
    super(props, context)

    if (storedMessage) {
      localStorage.removeItem('successMessage')
    }

    this.state = {
      user: {
        name: '',
        email: '',
        password: '',
        password2: ''
      },
      loading: false,
      errorStatus: false,
      planData: false
    }

    this.processForm = this.processForm.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.getData = this.getData.bind(this)
  }

  componentDidMount() {
    if (NAut.isAuthenticated) {
      return (window.location = './')
    }
    document.body.classList.add('login')
    // this.getData()
    window.location = 'https://www.picsize.com.br/#iniciar-teste'
  }

  async getData() {
    const { plan } = this.props.location.query
    const planData = await Api.get(`/plan/info?plan_code=${plan || 'default'}`)
    if (plan && planData) {
      return this.setState({ planData })
    }
  }

  handleChange(e) {
    const userState = this.state.user
    userState[e.target.name] = e.target.value
    this.setState(() => ({ user: userState }))
  }

  processForm(event) {
    event.preventDefault()
    const {
      plan,
      ll_sequence,
      ll_machine,
      ll_sequence_level,
      ll_source,
      ll_tag,
      ll_score
    } = this.props.location.query
    const ll = {
      ll_sequence,
      ll_machine,
      ll_sequence_level,
      ll_source,
      ll_tag,
      ll_score
    }
    const {
      name, email, password, password2
    } = this.state.user
    if (name.length < 1) {
      this.setState({ errorStatus: 'O nome não pode ficar em branco!' })
      return
    }
    if (password.length < 6) {
      this.setState({ errorStatus: 'A senha deve ter no mínimo 6 caracteres!' })
      return
    }
    if (password !== password2) {
      this.setState({ errorStatus: 'A senha digitada e a confirmação não coincidem!' })
      return
    }
    console.warn('Signup has been disabled here!')
    // SERVICE.trySignup(name, email, password, plan || 'default', ll)
    //   .then(async (res) => {
    //     if (!res.error) {
    //       try {
    //         await SERVICE.tryLogin(email, password)
    //       } catch (e) {
    //         //
    //       }
    //       return (window.location = './')
    //     }
    //     this.setState({ errorStatus: res.error })
    //   })
    //   .catch(() => this.setState({ errorStatus: 'Erro!' }))
  }

  render() {
    if (this.state.loading) return <div />
    return (
      <div>
        <div className="logo">
          <a href="http://painel.picsize.com.br/">
            <img src={fullLogo} alt="PICSIZE" />
          </a>
        </div>
        <div className="content">
          <SignupForm
            onSubmit={this.processForm}
            errorStatus={this.state.errorStatus}
            handleChange={this.handleChange}
            planData={this.state.planData}
          />
        </div>
        <div className="copyright">
          {' PICSIZE &copy;'}
          {new Date().getFullYear()}
        </div>
      </div>
    )
  }
}

Signup.propTypes = {
  router: PropTypes.object.isRequired,
  location: PropTypes.object
}

export default Signup
