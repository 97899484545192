import { BehaviorSubject } from 'rxjs'
import { BasicHttp } from './BasicHttp'

const userDataSbj = new BehaviorSubject(null)
let userData = null

userDataSbj.subscribe((data) => (userData = data))

export class NAut {
  static async init() {
    await this.getUserData()
  }

  static async login(email, password) {
    try {
      const loginRes = await BasicHttp.post('/auth/login', { email, password })

      if (!loginRes || !loginRes.success) {
        return Promise.reject(loginRes ? loginRes.error : 'UnexpectedError')
      }

      const data = await this.getUserData()

      if (!data || !data.id) {
        return Promise.reject(data ? data.error : 'UnexpectedError')
      }

      // userDataSbj.next(dataRes.data)

      return true
    } catch (ex) {
      console.warn(ex)
      return false
    }
  }

  static async logout(redir = true) {
    localStorage.removeItem('auth-time')
    localStorage.removeItem('auth-userData')
    localStorage.removeItem('auth-lastRemoteCheck')
    await BasicHttp.get('/auth/logout')
    if (!redir) {
      return true
    }
    return (window.location = './')
  }

  static async getUserData() {
    try {
      const res = await BasicHttp.get('/auth/user')

      if (res && res.success) {
        userDataSbj.next(res.data)
        localStorage.setItem('auth-lastRemoteCheck', Date.now())
        return res.data
      }

      if (res && res.error === 'NoTokenProvidedError') {
        userDataSbj.next(null)
        localStorage.removeItem('auth-lastRemoteCheck')
        return null
      }

      // eslint-disable-next-line no-console
      console.log(res)

      return Promise.reject((res && res.error) || 'UnexpectedError')
    } catch (ex) {
      console.warn(ex)
      return Promise.reject(ex || 'UnexpectedError')
    }
  }

  static get userData() {
    return userData
  }

  static get $userData() {
    return userDataSbj.asObservable()
  }

  static get lastSessionCheck() {
    return +localStorage.getItem('auth-lastRemoteCheck') || 0
  }

  static get isAuthenticated() {
    const b = !!userData && !!userData.id

    if (b) {
      trackUser()
    }

    if (b && !!this.lastSessionCheck && Date.now() - this.lastSessionCheck < 5 * 6e4) {
      this.getUserData()
    }

    return b
  }

  static get isPersonificated() {
    return false
  }

  static get isFreemium() {
    const { subscription } = this.userData
    return (
      (subscription
        && Array.isArray(subscription.plan_codes)
        && !!subscription.plan_codes.filter((c) => c === 'col_freemium').length)
      || false
    )
  }

  static get isTrial() {
    const { subscription } = this.userData

    if (!subscription || !subscription.trial || !subscription.expiration_date) {
      return false
    }

    return true
  }

  static get isTrialExpired() {
    const { subscription } = this.userData
    return subscription && this.isTrial && subscription.expired
  }
}

//

function trackUser() {
  if (window.isTrackingLoaded) return

  const {
    name, email, customer_id, subscription
  } = NAut.userData

  if (!NAut.isPersonificated) {
    window.dataLayer.push({
      ps_user: {
        name,
        email,
        customer_id,
        ...(!!subscription && {
          activation_date: subscription.activation_date,
          active: subscription.active,
          expired: subscription.expired,
          plan_ids: subscription.plan_ids,
          trial: subscription.trial
        })
      }
    })
  }

  window.isTrackingLoaded = true
}
