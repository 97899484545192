import React from 'react'

export default function Site(props) {
    const plan = {
        name: 'Ilimitado',
        albunsLimit: 'Ilimitado',
        picturesLimit: 'Ilimitado',
    }

    return (
        <>
            <div className="portlet light portlet-fit ">
                <div className="portlet-title">
                    <div className="caption">
                        <i className=" icon-layers font-green" />
                        <span className="caption-subject font-green bold uppercase">Site</span>
                        <h4 style={{marginTop: '20px'}}><strong>Plano: </strong>{plan.name}</h4>
                    </div>
                </div>
                <div className="portlet-body">
                    <div className="mt-element-list">
                        <div className="mt-list-container list-simple" style={{ marginTop: '-20px' }}>
                            <ul>
                                <li className="mt-list-item list-item-info" key={'feature-1'}>
                                    <div className="description item-1">
                                        <strong>Descrição</strong>
                                    </div>
                                    <div className="description item-2">
                                        <strong>Limite</strong>
                                    </div>
                                </li>
                                <li className="mt-list-item list-item-info" key={'feature-2'}>
                                    <div className="item-1">
                                        Álbuns
                                    </div>
                                    <div className="item-2">
                                        {plan.albunsLimit}
                                    </div>
                                </li>
                                <li className="mt-list-item list-item-info" key={'feature-3'}>
                                    <div className="item-1">
                                        Fotos
                                </div>
                                    <div className="item-2">
                                        {plan.picturesLimit}
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}