import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { NAut } from '~/modules/NAuth'
import ContactButton from '~/widgets/ContactButton'
import Sidebar from './Sidebar'
import TopHeader from './TopHeader'
import Helpers from './Helpers'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-switch/css/bootstrap-switch.min.css'
import 'styles/metronic/layout.min.css'
import 'styles/metronic/blue.min.css'
import 'styles/metronic/custom.min.css'

class Main extends Component {
  constructor(props) {
    super(props)
    this.state = {
      style: {}
    }
    this.updateHeight = this.updateHeight.bind(this)
  }

  componentDidMount() {
    checkPermission(this.props.location.pathname)
    document.body.classList.remove('login')
    this.updateHeight()
    window.addEventListener('resize', this.updateHeight)
    // Helpers.enableSidebarToggler()
    document.body.classList.add('page-header-fixed')
    document.body.classList.add('page-sidebar-closed-hide-logo')
    document.body.classList.add('page-container-bg-solid')
    document.body.classList.add('page-sidebar-closed')
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateHeight)
  }

  updateHeight() {
    this.setState({
      style: {
        minHeight: Helpers.viewport.height - 68
      }
    })
  }

  render() {
    return (
      <div>
        <div className="page-header navbar navbar-fixed-top">
          <TopHeader />
        </div>
        <div className="clearfix" />
        <div className="page-container">
          <Sidebar location={this.props.location} />
          <div className="page-content-wrapper">
            <div className="page-content" style={this.state.style}>
              {this.props.children}
            </div>
          </div>
        </div>
        <ContactButton />
      </div>
    )
  }
}

function checkPermission(path) {
  const { subscription } = NAut.userData
  if (!subscription) return
  if (!path.includes('/album/')) return
  if (subscription.limits && !subscription.limits.rev) {
    window.location = '/'
  }
}

Main.propTypes = {
  location: PropTypes.object.isRequired,
  children: PropTypes.element
}

Main.defaultProps = {
  children: null
}

export default Main
