import React from 'react'

import GalleryPro from './PlanCards/GalleryPro'
import Site from './PlanCards/Site'
import Albuns from './PlanCards/Albuns'
import GallerySelection from './PlanCards/GallerySelection'

function formatBytes(bytes, decimals) {
   if (bytes == 0) return '0 Bytes'
   let k = 1024,
       dm = decimals || 2,
       sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
       i = Math.floor(Math.log(bytes) / Math.log(k))
   return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

function formatNumber(num) {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
}

function parseStats (stats) {
  const {
    site: hasSite,
    sel_space: availableSpace,
    sel_max_pictures: maxPictures,
    sel_max_collections: maxCollections
  } = (stats.subscription || {}).limits
  const {
    sel_space: usedSpace,
    sel_pictures: picturesCount,
    sel_collections: collectionsCount
  } = stats.usage
  const limits = []
  limits.push({ name: 'Site', usage: hasSite ? 'Sim' : 'Não' })
  limits.push({ name: 'Seleção', usage: (availableSpace > 0) ? 'Sim' : 'Não' })
  if (availableSpace > 0) {
    limits.push({ name: 'Espaço em disco (seleção)', usage: `${formatBytes((+usedSpace) * 1024 * 1024, 2)} de ${formatBytes((+availableSpace) * 1024 * 1024, 2)}` })
    limits.push({ name: 'Coleções', usage: `${collectionsCount} de ${maxCollections || '\u221e'}` })
    limits.push({ name: 'Fotos em coleções', usage: `${picturesCount} de ${maxPictures || '\u221e'}` })
  }
  return limits
}
function EditAccountForm(props) {
  const status = props.stats ? parseStats(props.stats) : false
  const plan_codes = props.stats.subscription.plan_codes
  if (plan_codes.filter(plan_code => plan_code.startsWith('col')).length)  {
    return (
      <GalleryPro 
        usage={props.usage} 
        limits={props.limits} 
        plan={plan_codes.filter(plan_code => plan_code.startsWith('col'))[0]}
      />
    )
  }
  else {
    return (
      <GallerySelection 
        usage={props.usage} 
        limits={props.limits} 
        plan={plan_codes}
      />
    )
  }
}

export default EditAccountForm
