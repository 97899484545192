import React from 'react'

export default function Albuns(props) {
    let plan = []
    if(props.plan.includes('album_1')){
        plan = {
            name: '1 Álbum',
            albunsLimit: '1',
            sheetLimit: 'Ilimitado'
        }
    }
    else if(props.plan.includes('album_10')){
        plan = {
            name: '10 Álbuns',
            albunsLimit: '10',
            sheetLimit: 'Ilimitado'
        }
    }
    else if(props.plan.includes('album_premium')){
        plan = {
            name: 'Ilimitado',
            albunsLimit: 'Ilimitado',
            sheetLimit: 'Ilimitado'
        }
    }
    const usage = {
        albunsUsage: 0,
        sheetsUsage: 0,
    }

    return (
        <>
            <div className="portlet light portlet-fit ">
                <div className="portlet-title">
                    <div className="caption">
                        <i className=" icon-layers font-green" />
                        <span className="caption-subject font-green bold uppercase">Prova de Albuns</span>
                        <h4 style={{marginTop: '20px'}}><strong>Plano: </strong>{plan.name}</h4>
                    </div>
                </div>
                <div className="portlet-body">
                    <div className="mt-element-list">
                        <div className="mt-list-container list-simple" style={{ marginTop: '-20px' }}>
                            <ul>
                                <li className="mt-list-item list-item-info" key={'feature-1'}>
                                    <div className="description item-1">
                                        <strong>Descrição</strong>
                                    </div>
                                    <div className="description item-2">
                                        <strong>Limite</strong>
                                    </div>
                                </li>
                                <li className="mt-list-item list-item-info" key={'feature-2'}>
                                    <div className="item-1">
                                        Álbuns
                                    </div>
                                    <div className="item-2">
                                        {plan.albunsLimit}
                                    </div>
                                </li>
                                <li className="mt-list-item list-item-info" key={'feature-3'}>
                                    <div className="item-1">
                                        Lâminas
                                </div>
                                    <div className="item-2">
                                        {plan.sheetLimit}
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}