/* eslint-disable no-console */

import $ from 'jquery/src/core'
import 'jquery/src/ajax'
import 'jquery/src/ajax/xhr'

const ApiUrl = process.env.API_URL

export class BasicHttp {
  static get(path) {
    return new Promise((resolve) => {
      $.ajax(`${ApiUrl}${path}`, {
        method: 'GET',
        crossDomain: true,
        xhrFields: {
          withCredentials: true
        },
        success: resolve,
        error: (error) => {
          resolve({ error })
        }
      })
    })
  }

  static post(path, data) {
    return new Promise((resolve) => {
      $.ajax(`${ApiUrl}${path}`, {
        method: 'POST',
        data,
        crossDomain: true,
        xhrFields: {
          withCredentials: true
        },
        success: resolve,
        error: (error) => {
          console.warn(error)
          resolve({ error })
        }
      })
    })
  }
}
