import React from 'react'

import Site from './Site'
import Albuns from './Albuns'

import Format from '../../modules/Format'

export default function GallerySelection(props) {
    let plan = []
    if (props.plan.includes('sel_start')) {
        plan = {
            name: 'Start',
            diskLimit: 'Ilimitado',
            picturesLimit: '15.000',
            collectionLimit: 'Ilimitado',
            selectionLimit: 'Ilimitado',
        }
    }
    else if (props.plan.includes('sel_plus')) {
        plan = {
            name: 'Plus',
            diskLimit: 'Ilimitado',
            picturesLimit: '35.000',
            collectionLimit: 'Ilimitado',
            selectionLimit: 'Ilimitado',
        }
    }
    else if (props.plan.includes('sel_plus_old')) {
        plan = {
            name: 'Plus',
            diskLimit: '15 GB',
            picturesLimit: 'Ilimitado',
            collectionLimit: 'Ilimitado',
            selectionLimit: 'Ilimitado',
        }
    }
    else if (props.plan.includes('sel_premium')) {
        plan = {
            name: 'Ilimitado',
            diskLimit: 'Ilimitado',
            picturesLimit: 'Ilimitado',
            collectionLimit: 'Ilimitado',
            selectionLimit: 'Ilimitado',
        }
    }
    const usage = {
        diskUsage: Format.toMegaBytes(props.usage.galleryInfo.totalSize + props.usage.collectionInfo.totalSize),
        collectionUsage: props.usage.galleryInfo.count,
        selectionUsage: props.usage.collectionInfo.count,
        picturesUsage: Format.toNumber(props.usage.galleryInfo.totalPictures + props.usage.collectionInfo.totalPictures)
    }

    return (
        <>
            <div className='gallery-list-page'>
                <h1 className="page-title">Meu Plano</h1>
            </div>

            <div className="portlet light portlet-fit ">
                <div className="portlet-title">
                    <div className="caption">
                        <i className=" icon-layers font-green" />
                        <span className="caption-subject font-green bold uppercase">Galerias e Seleção</span>
                        <h4 style={{ marginTop: '20px' }}><strong>Plano: </strong>{plan.name}</h4>
                    </div>
                </div>
                <div className="portlet-body">
                    <div className="mt-element-list">
                        <div className="mt-list-container list-simple" style={{ marginTop: '-20px' }}>
                            <ul>
                                <li className="mt-list-item list-item-info" key={'feature-1'}>
                                    <div className="description item-1">
                                        <strong>Descrição</strong>
                                    </div>
                                    <div className="description item-2">
                                        <strong>Limite</strong>
                                    </div>
                                    <div className="description item-3">
                                        <strong>Uso</strong>
                                    </div>
                                </li>
                                <li className="mt-list-item list-item-info" key={'feature-2'}>
                                    <div className="item-1">
                                        Espaço em disco
                                    </div>
                                    <div className="item-2">
                                        {plan.diskLimit}
                                    </div>
                                    <div className="item-3">
                                        {usage.diskUsage}
                                    </div>
                                </li>
                                <li className="mt-list-item list-item-info" key={'feature-3'}>
                                    <div className="item-1">
                                        Fotos
                                </div>
                                    <div className="item-2">
                                        {plan.picturesLimit}
                                    </div>
                                    <div className="item-3">
                                        {usage.picturesUsage}
                                    </div>
                                </li>
                                <li className="mt-list-item list-item-info" key={'feature-4'}>
                                    <div className="item-1">
                                        Galerias para entrega
                                </div>
                                    <div className="item-2">
                                        {plan.collectionLimit}
                                    </div>
                                    <div className="item-3">
                                        {usage.collectionUsage}
                                    </div>
                                </li>
                                <li className="mt-list-item list-item-info" key={'feature-5'}>
                                    <div className="item-1">
                                        Galerias para seleção
                                </div>
                                    <div className="item-2">
                                        {plan.selectionLimit}
                                    </div>
                                    <div className="item-3">
                                        {usage.selectionUsage}
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            { props.limits.rev && <Albuns usage={props.usage} limits={props.limits} plan={props.plan} /> }
            { props.limits.site && <Site usage={props.usage} limits={props.limits} plan={props.plan} /> }
        </>
    )
}