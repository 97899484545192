import swal from 'sweetalert'

export async function overLimit() {
  const openPlans = await swal({
    dangerMode: true,
    text: 'Esta ação está fora do limite do seu plano atual! Faça um upgrade para poder continuar',
    title: 'Fora do plano',
    icon: 'error',
    buttons: {
      cancel: {
        text: 'Cancelar',
        value: false,
        visible: true,
        closeModal: true
      },
      confirm: {
        text: 'Fazer upgrade',
        value: true,
        className: 'good',
        visible: true,
        closeModal: true
      }
    }
  })
  if (openPlans) {
    window.location.href = 'https://www.picsize.com.br/upgrade'
  }
}

export async function notOnPlan() {
  const openPlans = await swal({
    dangerMode: true,
    text: 'O seu plano atual não inclui esta solução!',
    title: 'Fora do plano',
    icon: 'error',
    buttons: {
      cancel: {
        text: 'Cancelar',
        value: false,
        visible: true,
        closeModal: true
      },
      confirm: {
        text: 'Fazer upgrade',
        value: true,
        className: 'good',
        visible: true,
        closeModal: true
      }
    }
  })
  if (openPlans) {
    window.location.href = 'https://www.picsize.com.br/upgrade'
  }
}

export function expiredSubscriptionAlert() {
  const content = document.createElement('span')
  content.innerHTML = `<p>O período de testes da sua conta terminou.</p>
  <p>Para fazer a contratação acesse nossa <a href="https://www.picsize.com.br/planos" target="_blank">página de planos</a> e veja qual melhor se encaixa para a sua necessidade.</p>
  <p>Não se preocupe com os fotos já enviadas, elas estão guardadas com segurança, serão liberadas após a contratação de algum plano.</p>
  <p>Se ainda permanecer alguma dúvida, fale com o nosso suporte.</p>`

  swal({
    dangerMode: true,
    title: 'Assinatura expirada',
    content,
    icon: 'error',
    buttons: {
      cancel: {
        text: 'Fechar',
        value: false,
        visible: true,
        closeModal: true
      }
    }
  })
}

export function inactiveAccountAlert() {
  swal({
    dangerMode: true,
    text:
      'A sua conta está congelada, entre em contato com o nosso suporte para maiores informações.',
    title: 'Conta congelada',
    icon: 'error',
    buttons: {
      cancel: {
        text: 'Fechar',
        value: false,
        visible: true,
        closeModal: true
      }
    }
  })
}
