/* eslint-disable no-restricted-properties */
export default class Format {
  static toBytes(bytes, decimals) {
    // eslint-disable-next-line eqeqeq
    if (bytes == 0) return '0 Bytes'
    const k = 1024
    const dm = decimals || 2
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
  }

  static toMegaBytes(value) {
    return `${value} MB`
  }

  static toNumber(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
  }

  static toPlanName(plan_code){
    if (plan_code === 'col_premium') return 'Infinity Pro'
    const lowerCaseName = plan_code.replace('col_', '')
    return lowerCaseName.replace(lowerCaseName.charAt(0), lowerCaseName.charAt(0).toUpperCase())
  }
}
