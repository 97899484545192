import React, { Component } from 'react'
import swal from 'sweetalert'
import PropTypes from 'prop-types'

import Loading from '~/widgets/Loading'
import Api from '~/modules/Api'
import { NAut } from '~/modules/NAuth'

import AccountDataForm from './AccountForm'

import './style.scss'

class AccountInfo extends Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      details: null,
      inputs: null
    }

    this.getData = this.getData.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.submit = this.submit.bind(this)
  }

  componentDidMount() {
    this.getData()
  }

  async getData() {
    const details = await Api.get('/account/details')
    if (!details.error) {
      return this.setState({ details })
    }
  }

  handleChange(e) {
    const { inputs } = this.state
    inputs[e.target.name] = e.target.value
    this.setState({ inputs })
  }

  // eslint-disable-next-line class-methods-use-this
  async submit(data) {
    const response = await Api.post('/account/edit', data)

    if (response.exception) {
      await swal('Erro!', 'Não foi possível atualizar os dados', 'error')
      window.location.reload()
    }
    if (!response.success) {
      return response
    }

    await NAut.getUserData()
    await swal('Pronto!', 'Dados atualizados', 'success')
    window.location.reload()
  }

  render() {
    if (!this.state.details) return <Loading />
    return (
      <div className="portlet-body form">
        <AccountDataForm details={this.state.details} submit={this.submit} />
      </div>
    )
  }
}

AccountInfo.contextTypes = {
  router: PropTypes.object.isRequired
}

export default AccountInfo
