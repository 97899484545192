import React from 'react'

import Site from './Site'

import Format from '../../modules/Format'

export default function GalleryPro(props) {
    const plan = {
        name: props.plan ? Format.toPlanName(props.plan) : '',
        diskLimit: (props.limits.col_space + props.limits.sel_space) > 9e6 || (!props.limits.col_space && !props.limits.col_space) ? 'Ilimitado' : Format.toBytes(((props.limits.col_space || 0) + (props.limits.sel_space || 0)) * 1024 * 1024, 2),
        picturesLimit: (props.limits.col_max_pictures + props.limits.sel_max_pictures) > 9e6 || (!props.limits.col_max_pictures && !props.limits.sel_max_pictures) ? 'Ilimitado' : Format.toNumber((props.limits.col_max_pictures || 0) + (props.limits.sel_max_pictures || 0)),
        collectionLimit: props.limits.col_max_collections >= 999 || !props.limits.col_max_collections ? 'Ilimitado' : props.limits.col_max_collections,
        selectionLimit: props.limits.sel_max_collections >= 999 || !props.limits.sel_max_collections ? 'Ilimitado' : props.limits.sel_max_collections,
    }
    const usage = {
        diskUsage: Format.toBytes((props.usage.galleryInfo.totalSize + props.usage.collectionInfo.totalSize) * 1024 * 1024, 2),
        collectionUsage: props.usage.galleryInfo.count,
        selectionUsage: props.usage.collectionInfo.count,
        picturesUsage: Format.toNumber(props.usage.galleryInfo.totalPictures + props.usage.collectionInfo.totalPictures)
    }

    return (
        <>
            <div className='gallery-list-page'>
                <h1 className="page-title">Meu Plano</h1>
            </div>

            <div className="portlet light portlet-fit ">
                <div className="portlet-title">
                    <div className="caption">
                        <i className=" icon-layers font-green" />
                        <span className="caption-subject font-green bold uppercase">Gallery Pro</span>
                        <h4 style={{marginTop: '20px'}}><strong>Plano: </strong>{plan.name}</h4>
                    </div>
                </div>
                <div className="portlet-body">
                    <div className="mt-element-list">
                        <div className="mt-list-container list-simple" style={{ marginTop: '-20px' }}>
                            <ul>
                                <li className="mt-list-item list-item-info" key={'feature-1'}>
                                    <div className="description item-1">
                                        <strong>Descrição</strong>
                                    </div>
                                    <div className="description item-2">
                                        <strong>Limite</strong>
                                    </div>
                                    <div className="description item-3">
                                        <strong>Uso</strong>
                                    </div>
                                </li>
                                <li className="mt-list-item list-item-info" key={'feature-2'}>
                                    <div className="item-1">
                                        Espaço em disco
                                    </div>
                                    <div className="item-2">
                                        {plan.diskLimit}
                                    </div>
                                    <div className="item-3">
                                        {usage.diskUsage}
                                    </div>
                                </li>
                                <li className="mt-list-item list-item-info" key={'feature-3'}>
                                    <div className="item-1">
                                        Fotos
                                </div>
                                    <div className="item-2">
                                        {plan.picturesLimit}
                                    </div>
                                    <div className="item-3">
                                        {usage.picturesUsage}
                                    </div>
                                </li>
                                <li className="mt-list-item list-item-info" key={'feature-4'}>
                                    <div className="item-1">
                                        Galerias para entrega
                                </div>
                                    <div className="item-2">
                                        {plan.collectionLimit}
                                    </div>
                                    <div className="item-3">
                                        {usage.collectionUsage}
                                    </div>
                                </li>
                                <li className="mt-list-item list-item-info" key={'feature-5'}>
                                    <div className="item-1">
                                        Galerias para seleção
                                </div>
                                    <div className="item-2">
                                        {plan.selectionLimit}
                                    </div>
                                    <div className="item-3">
                                        {usage.selectionUsage}
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            { props.limits.site && <Site usage={props.usage} limits={props.limits} plan={props.plan} /> }
        </>
    )
}