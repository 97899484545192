/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { Link } from 'react-router'
import { Dropdown, Menu } from 'antd'

import { NAut } from '~/modules/NAuth'

const menu = (
  <Menu style={{ zIndex: 1000 }}>
    <Menu.Item key="0">
      <Link to="/account/info">
        <i className="icon-user" />
        {' '}
        <span>Minha Conta</span>
      </Link>
    </Menu.Item>
    <Menu.Item key="1">
      <Link to="/account/profile">
        <i className="icon-user" />
        {' '}
        <span>Perfil</span>
      </Link>
    </Menu.Item>
    <Menu.Item key="2">
      <Link to="/account/financial">
        <i className="icon-wallet" />
        {' '}
        <span>Financeiro</span>
      </Link>
    </Menu.Item>
    <Menu.Item key="3">
      <Link to="/logout">
        <i className="icon-key" />
        {' '}
        <span>Sair</span>
      </Link>
    </Menu.Item>
  </Menu>
)

class TopMenu extends Component {
  constructor(props, context) {
    super(props, context)

    this.state = {
      user: null
    }

    this.$destroy = new Subject()
  }

  componentDidMount() {
    NAut.$userData.pipe(takeUntil(this.$destroy)).subscribe((user) => this.setState({ user }))
  }

  componentWillUnmount() {
    this.$destroy.next()
    this.$destroy.complete()
  }

  render() {
    return (
      <div className="top-menu">
        <ul className="nav navbar-nav pull-right">
          <li className="dropdown dropdown-user">
            <Dropdown overlay={menu} trigger={['click']}>
              <a className="dropdown-toggle">
                <span className="username username-hide-on-mobile">
                  {this.state.user && (this.state.user.displayName || this.state.user.name || 'Eu')}
                </span>
                <i className="fa fa-angle-down" />
              </a>
            </Dropdown>
          </li>
        </ul>
      </div>
    )
  }
}

TopMenu.propTypes = {}
TopMenu.defaultProps = {}

export default TopMenu
