import ReactDOM from 'react-dom'

import 'jquery'
import 'bootstrap'
import AppComponent from './app'

import 'font-awesome/css/font-awesome.css'
import 'simple-line-icons/simple-line-icons.min.css'
import 'fonts/picsize.css'
import 'fonts/knowledge/knowledge.css'
import 'styles/metronic/lib.scss'
import 'ladda-bootstrap/dist/ladda-themeless.min.css'
import 'styles/metronic/components-rounded.min.css'

import 'custom/widgets.scss'
import 'styles/custom/sidebar.scss'

import './style.scss'

function inIframe() {
  try {
    return window.self !== window.top
  } catch (e) {
    return true
  }
}

if (inIframe()) {
  window.top.location.href = window.location.href
}

ReactDOM.render(<AppComponent />, document.getElementById('root'))
