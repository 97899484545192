/* eslint-disable no-underscore-dangle */
import Api from './Api'
import { BehaviorSubject } from 'rxjs'
import { filter, map } from 'rxjs/operators'
import { NAut } from '~/modules/NAuth'

class User {
  _stats = {
    ready: false,
    collectionInfo: {
      count: 0,
      last: null,
      totalPictures: 0,
      totalSize: 0
    },
    galleryInfo: {
      count: 0,
      last: null,
      totalPictures: 0,
      totalSize: 0
    }
  }

  $stats = new BehaviorSubject()

  get stats() {
    return this.$stats.asObservable().pipe(
      filter(() => typeof this._stats === 'object' && this._stats.ready === true),
      map(() => this._stats)
    )
  }

  async refreshStats() {
    try {
      if (!NAut.isAuthenticated) {
        throw new Error('NotAuthenticatedError')
      }

      const result = await Api.get('/customer/collection')

      if (!result && !result.success) {
        throw result
      }

      const { collection_info, gallery_info } = result

      this._stats = {
        ready: true,
        collectionInfo: {
          count: +collection_info.count,
          last: new Date(collection_info.last),
          totalPictures: +collection_info.total_pictures,
          totalSize: +collection_info.total_size
        },
        galleryInfo: {
          count: +gallery_info.count,
          last: new Date(gallery_info.last),
          totalPictures: +gallery_info.total_pictures,
          totalSize: +gallery_info.total_size
        }
      }

      this.$stats.next()
    } catch (ex) {
      console.warn(ex)
    }
  }
}

export default new User()
