/* eslint-disable brace-style */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import swal from 'sweetalert'

import { NAut } from '~/modules/NAuth'
import * as AuthAlerts from '~/modules/NAuth/Alerts'
import Api from '~/modules/Api'
import LoginForm from '~/widgets/LoginForm'

import fullLogo from 'img/picsize.png'
import 'custom/login.scss'
import 'custom/auth-with.scss'

function isValidEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

class Login extends Component {
  constructor(props, context) {
    const storedMessage = localStorage.getItem('successMessage')
    super(props, context)

    if (storedMessage) {
      localStorage.removeItem('successMessage')
    }

    this.state = {
      busy: false,
      user: { email: '', password: '' },
      errorStatus: false
    }

    this.processForm = this.processForm.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.recoverPassword = this.recoverPassword.bind(this)
    this.setup()
  }

  componentDidMount() {
    document.body.classList.add('login')
  }

  // eslint-disable-next-line class-methods-use-this
  async setup() {
    if (NAut.isAuthenticated) {
      redirToService('select')
    } else {
      window.location = 'https://gallery.picsize.com.br/login'
    }
  }

  static setupAuthWith() {
    if (document.getElementById('auth-with-js')) {
      if (AuthWith && typeof AuthWith.init === 'function') {
        AuthWith.init()
      }
      return
    }
    const s = document.createElement('script')
    s.id = 'auth-with-js'
    s.type = 'text/javascript'
    s.src = 'https://cadastro.picsize.com.br/auth-with.js?1.0.2'
    s.onload = () => {
      if (AuthWith && typeof AuthWith.init === 'function') {
        AuthWith.init()
      }
    }
    document.body.appendChild(s)
  }

  // eslint-disable-next-line react/sort-comp
  static focusOn(id) {
    const el = document.getElementById(id)
    if (el && typeof el.focus === 'function') {
      el.focus()
    }
  }

  handleChange(e) {
    const userState = this.state.user
    userState[e.target.name] = e.target.value
    this.setState(() => ({ user: userState }))
  }

  // eslint-disable-next-line class-methods-use-this
  async recoverPassword(e) {
    e.preventDefault()
    const email = await swal({
      content: {
        element: 'input',
        attributes: {
          placeholder: 'Digite o e-mail da conta',
          type: 'email'
        }
      }
    })
    if (!isValidEmail(email)) {
      return swal('', 'Email inválido!', 'error')
    }
    await Api.post(`/account/requestRecover?email=${email}`)
    await swal(
      'Pronto',
      'Em breve você receberá um email com as instruções de recuperação de senha!',
      'success'
    )
  }

  // eslint-disable-next-line max-statements
  async processForm(event) {
    event.preventDefault()
    if (this.state.busy) {
      return
    }
    this.setState({ busy: true, errorStatus: null })
    const { email, password } = this.state.user
    try {
      const res = await NAut.login(email, password)
      if (res !== true) {
        throw res
      }
      // TODO: Rewrite
      redirToService('select')
    } catch (ex) {
      if (ex === 'UserNotFoundError') {
        // Invalid Email
        this.setState({ errorStatus: 'email', user: { email, password: '' } })
        Login.focusOn('login-inp-email')
      } else if (ex === 'InvalidPasswordError') {
        // Invalid Password
        this.setState({ errorStatus: 'password', user: { email, password: '' } })
        Login.focusOn('login-inp-password')
      } else if (ex === 'InactiveAccountError') {
        // Desactivated Account
        this.setState({ busy: false })
        AuthAlerts.inactiveAccountAlert()
      } else if (ex === 'ExpiredSubscriptionError') {
        // Expired Plan
        this.setState({ busy: false })
        AuthAlerts.expiredSubscriptionAlert()
      } else {
        // Others
        this.setState({ errorStatus: 'Algo deu errado.' })
        console.warn(ex)
      }
    }
    this.setState({ busy: false })
  }

  render() {
    return (
      <div>
        {/* <div className="logo">
          <a href="http://painel.picsize.com.br/">
            <img src={fullLogo} alt="PICSIZE" />
          </a>
        </div>
        <div className="content">
          <LoginForm
            busy={this.state.busy}
            model={this.state.user}
            onSubmit={this.processForm}
            errorStatus={this.state.errorStatus}
            handleChange={this.handleChange}
            recoverPassword={this.recoverPassword}
          />
        </div>
        <div className="copyright">
          {' '}
          PICSIZE &copy;
          {new Date().getFullYear()}
        </div> */}
      </div>
    )
  }
}

Login.propTypes = {
  router: PropTypes.object.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  location: PropTypes.object.isRequired
}

export default Login

// TODO: Rewrite
async function redirToService(service) {
  const result = await Api.post('/cross/url', { service })
  if (!result || !result.url) {
    console.warn(result)
    return
  }
  window.location = result.url
}
