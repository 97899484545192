import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Loading from '~/widgets/Loading'
import PlanStatus from '~/widgets/PlanStatus'
import Api from '~/modules/Api'
import CustomerService from '~/modules/CustomerService'

import './style.scss'

class AccountInfo extends Component {
  $customerService

  constructor(props, context) {
    super(props, context)
    this.state = {
      stats: false,
      usage: false,
      limits: false
    }

    this.getData = this.getData.bind(this)
  }

  componentDidMount() {
    this.getData()
    this.$customerService = CustomerService.stats.subscribe((data) => {
      this.setState({
        usage: {
          galleryInfo: data.galleryInfo,
          collectionInfo: data.collectionInfo
        }
      })
    })
  }

  componentWillUnmount() {
    this.$customerService.unsubscribe()
  }

  async getData() {
    const stats = await Api.get('/customer/stats')
    if (stats) {
      return this.setState({
        stats,
        limits: stats.subscription.limits
      })
    }
  }

  render() {
    if (!this.state.stats || !this.state.usage) return <Loading />
    const { plan_codes } = this.state.stats.subscription
    return (
      <div className="row">
        <div className="col-md-4">
          <PlanStatus
            stats={this.state.stats}
            usage={this.state.usage}
            limits={this.state.limits}
          />
        </div>
        <div className="col-md-8">
          <div className="gallery-list-page">
            <h1 className="page-title">
              {this.props.children.props.route.path === 'info' ? 'Meus Dados' : 'Financeiro'}
            </h1>
          </div>
          <div />
          <div className="portlet light" style={{ paddingBottom: '20px' }}>
            {this.props.children}
          </div>
        </div>
      </div>
    )
  }
}

AccountInfo.propTypes = {
  router: PropTypes.object.isRequired,
  children: PropTypes.any.isRequired
}

export default AccountInfo
