/* eslint-disable react/jsx-pascal-case */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'

const navigationMenu = [
  {
    route: 'http://select.picsize.com.br/home',
    title: 'Início',
    icon: 'icon-home',
    disable: false
  },
  {
    route: 'http://select.picsize.com.br/gallery',
    defaultRoute: 'http://select.picsize.com.br/gallery/list',
    title: 'Galerias para Entrega',
    icon: 'fo fo-pictures',
    disable: false,
    subroutes: [
      {
        route: 'http://select.picsize.com.br/gallery/list',
        title: 'Minhas Entregas'
      },
      {
        route: 'http://select.picsize.com.br/gallery',
        title: 'Nova Entrega',
        exact: true
      }
    ]
  },
  {
    route: 'http://select.picsize.com.br/collection',
    defaultRoute: 'http://select.picsize.com.br/collection/list',
    title: 'Galerias para Seleção',
    icon: 'fo fo-check-picture',
    subroutes: [
      {
        route: 'http://select.picsize.com.br/collection/list',
        title: 'Minhas Seleções'
      },
      {
        route: 'http://select.picsize.com.br/collection/new',
        title: 'Nova Seleção'
      }
    ]
  },
  {
    route: 'http://select.picsize.com.br/album',
    defaultRoute: 'http://select.picsize.com.br/album/list',
    title: 'Prova de Álbuns',
    icon: 'fo fo-open-book-5',
    subroutes: [
      {
        route: 'http://select.picsize.com.br/album/list',
        title: 'Meus Álbuns'
      },
      {
        route: 'http://select.picsize.com.br/album/new',
        title: 'Novo Álbum'
      }
    ]
  },
  {
    route: 'http://select.picsize.com.br/knowledge',
    defaultRoute: 'http://select.picsize.com.br/knowledge/videos',
    title: 'Área do Conhecimento',
    icon: 'knowledge',
    subroutes: [
      {
        route: 'http://select.picsize.com.br/knowledge/videos',
        title: 'Videos'
      }
    ]
  },
  {
    route: 'http://select.picsize.com.br/settings',
    defaultRoute: 'http://select.picsize.com.br/settings/customize',
    title: 'Configurações',
    icon: 'icon-settings',
    subroutes: [
      {
        route: 'http://select.picsize.com.br/settings/customize',
        title: 'Personalização'
      },
      {
        route: 'http://select.picsize.com.br/settings/watermark',
        title: "Marca d'água"
      }
    ]
  }
]

const selectNavMenu = [
  {
    route: 'http://select.picsize.com.br/collection',
    defaultRoute: 'http://select.picsize.com.br/collection/list',
    title: 'Seleção de fotos',
    icon: 'fa fa-folder-open',
    subroutes: [
      {
        route: 'http://select.picsize.com.br/collection/list',
        title: 'Minhas Coleções'
      },
      {
        route: 'http://select.picsize.com.br/collection/new',
        title: 'Nova Coleção'
      },
      {
        route: 'http://select.picsize.com.br/collection/settings/customize',
        title: 'Personalização'
      },
      {
        route: 'http://select.picsize.com.br/collection/settings/watermark',
        title: "Marca d'água"
      }
    ]
  },
  {
    route: 'http://select.picsize.com.br/album',
    defaultRoute: 'http://select.picsize.com.br/album/list',
    title: 'Prova de álbuns',
    icon: 'fa fa-book',
    subroutes: [
      {
        route: 'http://select.picsize.com.br/album/list',
        title: 'Meus álbuns'
      },
      {
        route: 'http://select.picsize.com.br/album/new',
        title: 'Novo álbum'
      }
    ]
  }
]

const WORKAROUND_Link = (props) => {
  if (props.to.startsWith('http')) {
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    return <a href={props.to} {...props} />
  }
  return <Link {...props} />
}

WORKAROUND_Link.propTypes = {
  to: PropTypes.any
}

class Sidebar extends Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      navigationMenu
    }

    this.activeRoute = this.isActive.bind(this)

    // TODO: melhorar método de filtrar menu

    // const { subscription } = Auth.userData
    // if (!subscription) return
    // if (subscription.limits && !subscription.limits.rev) {
    //   this.state.navigationMenu = navigationMenu.filter((p) => !p.route.includes('/album'))
    // }

    // // Se vem do painel select:
    // if (window.location.hash && window.location.hash.length > 0) {
    //   this.state.navigationMenu = selectNavMenu
    // }
  }

  isActive(routeName) {
    return this.props.location.pathname.includes(routeName)
  }

  render() {
    return (
      <div className="page-sidebar-wrapper">
        <div className="page-sidebar navbar-collapse collapse">
          <ul
            className="page-sidebar-menu menu-fixed page-header-fixed page-sidebar-menu-hover-submenu page-sidebar-menu-closed"
            data-keep-expanded="false"
            data-auto-scroll="true"
            data-slide-speed={200}
          >
            {this.state.navigationMenu.map((mainItem, mainIndex) => (mainItem.disable ? null : (
              <li
                className={`nav-item ${this.isActive(mainItem.route) && 'active open'}`}
                key={mainIndex}
              >
                <WORKAROUND_Link
                  to={mainItem.defaultRoute || mainItem.route}
                  className="nav-link nav-toggle custom-item"
                >
                  <i
                    className={mainItem.icon}
                    style={{ color: !this.isActive(mainItem.route) && 'white' }}
                  />
                  <span className="title main">{mainItem.title}</span>
                  {this.isActive(mainItem.route) && <span className="selected" />}
                  <span className="arrow" />
                </WORKAROUND_Link>
                {!mainItem.subroutes ? null : (
                  <ul className="sub-menu">
                    {mainItem.subroutes.map((subItem, subIndex) => (
                      <li
                        key={`${mainIndex}${subIndex}`}
                        className={`nav-item ${this.isActive(subItem.route) && 'active open'}`}
                      >
                        <WORKAROUND_Link to={subItem.route} className="nav-link nav-toggle">
                          <span className="title">{subItem.title}</span>
                        </WORKAROUND_Link>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            )))}
          </ul>
        </div>
      </div>
    )
  }
}

Sidebar.propTypes = {
  location: PropTypes.object
}

export default Sidebar
