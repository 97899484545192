import React, { Component } from 'react'
import { Divider } from 'antd'

class Financial extends Component {

    render() {
        return (
            <div className='portlet-body financial-wrap'>
                <Divider orientation="left">Financeiro</Divider>
                <div className='financial-content'>
                    <p>
                        No painel do financeiro você poderá:
                    </p>
                        <ul>
                            <li>Ver o histórico de pagamentos</li>
                            <li>Gerar 2ª via do boleto</li>
                            <li>Alterar os dados do seu cartão de crédito</li>
                        </ul>
                    <p>
                        Para acessá-lo, basta clicar no botão abaixo.
                    </p>
                    <p>
                        <strong>Obs.:</strong> Será solicitado seu e-mail e sua senha, porém, lembre-se que esses dados não são 
                        os mesmos utilizados para acessar essa plataforma. Portanto, utilize o recurso de lembrar 
                        a senha caso não a possua.
                    </p>
                </div>
                <Divider />
                <div className='financial-footer'>
                    <a className='ant-btn ant-btn-primary' target='blank' href="https://picsize.superlogica.net/clients/areadocliente/clientes/formadepagamento">Acessar Painel Financeiro</a>
                </div>
            </div>
        )
    }

}

export default Financial
