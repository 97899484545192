import React from 'react'
import {Link} from 'react-router'

function SignupForm(props) {
  return (
    <form onSubmit={props.onSubmit}>
      <Alert error={props.errorStatus}/>
      <div className="form-group email user_email">
        <label
          className="control-label email">Nome</label>
        <input
          className="form-control string email" type="text" onChange={props.handleChange} name="name"/>
      </div>
      <div className="form-group email user_email">
        <label
          className="control-label email">Email</label>
        <input
          className="form-control string email" type="text" style={{textTransform: "lowercase"}} onChange={props.handleChange} name="email"/>
      </div>
      <div className="form-group password user_password">
        <label
          className="control-label password"
          htmlFor="password">Senha</label>
        <input
          className="form-control password" type="password" onChange={props.handleChange} name="password"/>
      </div>
      <div className="form-group password user_password2">
        <label
          className="control-label password"
          htmlFor="password2">Confirmação de Senha</label>
        <input
          className="form-control password" type="password" onChange={props.handleChange} name="password2"/>
      </div>
      {
        props.planData ?
        <div className="form-group plan_info">
          <strong>Plano: </strong>
           {Array.isArray(props.planData) ? (
             props.planData.map((p) => p.name).join(', ')
           ) : (
             props.planData.name
           )}
        </div>
        : null
      }
      <div className="form-actions">
        <div className="row">
          <div className="col-md-6">
            <Link to="/login">
              Voltar
            </Link>
          </div>
          <div className="col-md-6">
            <input
              type="submit"
              name="commit"
              defaultValue="Finalizar cadastro"
              className="btn signup pull-right uppercase" />
          </div>
          </div>
        </div>
      </form>
  )
}

function Alert(props) {
  if (!props.error) return null
  return (
    <div className="alert alert-danger animated fadeIn" >
      {props.error}
    </div>
  )
}

export default SignupForm
