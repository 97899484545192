import React, { Component } from 'react'
import swal from 'sweetalert'
import PropTypes from 'prop-types'

import Loading from '~/widgets/Loading'
import Api from '~/modules/Api'
import { NAut } from '~/modules/NAuth'

import AccountProfileForm from './ProfileForm'

import './style.scss'

export default class AccountProfile extends Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      inputs: null
    }

    this.getData = this.getData.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.submit = this.submit.bind(this)
  }

  componentDidMount() {
    this.getData()
  }

  async getData() {
    const profile = await Api.get('/account/profile')
    if (!profile.error) {
      return this.setState({ profile })
    }
  }

  handleChange(e) {
    const { inputs } = this.state
    inputs[e.target.name] = e.target.value
    this.setState({ inputs })
  }

  // eslint-disable-next-line class-methods-use-this
  async submit(data) {
    const response = await Api.post('/account/profile', data)

    if (response.exception) {
      await swal('Erro!', 'Não foi possível atualizar os dados', 'error')
      window.location.reload()
    }
    if (!response.success) {
      return response
    }

    await NAut.getUserData()
    await swal('Pronto!', 'Dados atualizados', 'success')
    // window.location.reload()
  }

  render() {
    if (!this.state.profile) return <Loading />
    return (
      <div className="row">
        <div className="col-md-8 col-md-offset-2">
          <div className="portlet light" style={{ paddingBottom: '20px' }}>
            <div className="portlet-body form">
              <AccountProfileForm data={this.state.profile} submit={this.submit} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

AccountProfile.contextTypes = {
  router: PropTypes.object.isRequired
}
