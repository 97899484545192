import React, { Component } from 'react'

import {
  Input,
  Icon,
  Button,
  Form,
  Tooltip,
  Divider,
} from 'antd'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 7 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12 }
  },
  className: 'ps-ant-formItem'
}

export default class ProfileForm extends Component {
  fieldKeys = ['displayName', 'displayEmail', 'telephone', 'bio']
  socialMediaKeys = ['site', 'instagram', 'facebook', 'twitter']

  constructor(props, context) {
    super(props, context)

    const state = { isSubmiting: false }
    for (const k of this.fieldKeys) {
      state[k] = props.data[k] || ''
    }
    for (const k of this.socialMediaKeys) {
      if (!props.data.socialMedias) {
        state[k] = ''
      }
      else {
        state[k] = props.data.socialMedias[k] || ''
      }
    }
    this.state = state

    this.onChange = this.onChange.bind(this)
  }

  onChange(value, key) {
    if (!key) {
      key = value.target.name
      value = value.target.value
    }
    this.setState({ [key]: value })
  }

  onSubmit(e) {
    e.preventDefault()
    const data = {}

    this.setState({ isSubmiting: true })

    for (const k of this.fieldKeys) {
      if (this.props.data[k] !== this.state[k]) {
        data[k] = this.state[k]
      }
    }
    for (const k of this.socialMediaKeys) {
      if (!this.props.data.socialMedias && !this.state[k]) {
        continue
      }
      if (!this.props.data.socialMedias || this.props.data.socialMedias[k] !== this.state[k]) {
        data.socialMedias = this.socialMediaKeys.reduce((p, c) => {
          p[c] = this.state[c]
          return p
        }, {})
        break
      }
    }

    this
      .props
      .submit(data)
      .then((response) => {
        this.setState({
          isSubmiting: false,
          ...response
        })
      })
  }

  render() {
    const tooltipTitle = (<div style={{ padding: '5px 10px', margin: 0 }}>
      <p style={{ padding: 0, margin: 0 }}>
        Esse nome será utilizado nos e-mails e páginas públicas para identificar o seu trabalho. Deve ser o seu nome comercial.
      </p>
    </div>)
    return (
      <Form layout="horizontal" onSubmit={this.onSubmit.bind(this)}>
        <Divider orientation="left">Perfil</Divider>
        <Form.Item
          {...formItemLayout}
          label={
            <span>
              Nome para exibição&nbsp;
              <Tooltip title={tooltipTitle} placement='bottom'>
                <Icon type="question-circle-o" />
              </Tooltip>
            </span>
          }
        >
          <Input
            name="displayName"
            value={this.state.displayName}
            onChange={this.onChange}
            prefix={<Icon type="global" style={{ color: 'rgba(0,0,0,.25)' }} />}
          />
        </Form.Item>

        <Form.Item label="E-mail para exibição" {...formItemLayout}>
          <Input
            name="displayEmail"
            prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
            style={{ textTransform: "lowercase" }}
            value={this.state.displayEmail}
            onChange={this.onChange}
          />
        </Form.Item>
        <Form.Item label="Telefone" {...formItemLayout} wrapperCol={{ xs: { span: 24 }, sm: { span: 7 } }}>
          <Input
            name="telephone"
            prefix={<Icon type="phone" style={{ color: 'rgba(0,0,0,.25)' }} />}
            value={this.state.telephone}
            onChange={this.onChange}
          />
        </Form.Item>
        <Form.Item label="Site" {...formItemLayout} help='ex.: https://www.picsize.com.br'>
          <Input
            name="site"
            prefix={<i className='fa fa-globe' style={{ color: 'rgba(0,0,0,.25)' }}></i>}
            style={{ textTransform: "lowercase" }}
            value={this.state.site}
            onChange={this.onChange}
          />
        </Form.Item>
        <br />
        <Form.Item label="Biografia" {...formItemLayout}>
          <Input.TextArea
            name="bio"
            prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
            value={this.state.bio}
            onChange={this.onChange}
            rows='5'
          />
        </Form.Item>

        <Form.Item label="Instagram" {...formItemLayout} help='ex.: https://www.instagram.com/picsize_'>
          <Input
            name="instagram"
            prefix={<Icon type="instagram" style={{ color: 'rgba(0,0,0,.25)' }} />}
            style={{ textTransform: "lowercase" }}
            value={this.state.instagram}
            onChange={this.onChange}
          />
        </Form.Item>
        <br />
        <Form.Item label="Facebook" {...formItemLayout} help='ex.: https://facebook.com/picsizeplataforma'>
          <Input
            name="facebook"
            prefix={<Icon type="facebook" style={{ color: 'rgba(0,0,0,.25)' }} />}
            style={{ textTransform: "lowercase" }}
            value={this.state.facebook}
            onChange={this.onChange}
          />
        </Form.Item>
        <br />
        <Form.Item label="Twitter" {...formItemLayout} help='ex.: https://twitter.com/picsize_'>
          <Input
            name="twitter"
            prefix={<Icon type="twitter" style={{ color: 'rgba(0,0,0,.25)' }} />}
            style={{ textTransform: "lowercase" }}
            value={this.state.twitter}
            onChange={this.onChange}
          />
        </Form.Item>


        <Divider />
        <Form.Item wrapperCol={{ span: 4, offset: 20 }}>
          <Button
            loading={this.state.isSubmiting}
            type="primary"
            htmlType="submit"
            style={{ width: '100%' }}
          >
            Salvar dados
          </Button>
        </Form.Item>
      </Form>
    )
  }
}
